import React from "react";
import { NavLink } from "react-router-dom";
import "./topics.styles.css";

const TechnicalReports = () => {
  return (
    <div className="content">
      <h1>Topical Reports</h1>
      <div>
        <ul>
          {/* <li style={{ display: "flex" }}> */}
          <li className="topics-item">
            <span className="topics-h">Topic 1 &nbsp; -&nbsp;</span>{" "}
            <NavLink to="/topical-reports/tr-fs-ati">
              <span>Assistive Technology Instrument Update and Review</span>
            </NavLink>
          </li>

          <li className="topics-item">
            <span className="topics-h">Topic 2 &nbsp; -&nbsp;</span>{" "}
            <NavLink to="/topical-reports/tr-instruments">
              <span
              // style={{ textDecoration: "underline" }}
              >
                The Inclusion of Assistive Technology Outcomes in Current Health
                and Rehabilitation Outcome Measures
              </span>
            </NavLink>
          </li>
          <li className="topics-item">
            <span className="topics-h">Topic 3 &nbsp; -&nbsp;</span>
            <NavLink to="/topical-reports/tr-atproddev">
              Outcome Measures Used in AT Research &amp; Development
            </NavLink>
          </li>

          <li className="topics-item">
            <span className="topics-h">Topic 4 &nbsp; -&nbsp;</span>
            <NavLink to="/topical-reports/tr-fs-datacollection">
              Next Generation Data Collection
            </NavLink>
          </li>

          <li className="topics-item">
            <span className="topics-h">Topic 5 &nbsp; -&nbsp;</span>
            <NavLink to="/topical-reports/tr-cost">
              Comparison of Cost Outcome Methods
            </NavLink>
          </li>

          <li className="topics-item">
            <span className="topics-h">Topic 6 &nbsp; -&nbsp;</span>
            <NavLink to="/topical-reports/tr-mau">
              Use of Multi-attribute (MAU) and Bayes Approaches in Outcomes Data
              Collection
            </NavLink>
          </li>

          <li className="topics-item">
            <span className="topics-h">Topic 7 &nbsp; -&nbsp;</span>
            <NavLink to="/topical-reports/tr-fs-taxonomiesmodels">
              Models and Taxonomies Relating to Assistive Technology
            </NavLink>
          </li>

          <li className="topics-item">
            <span className="topics-h">Topic 8 &nbsp; -&nbsp;</span>
            <NavLink to="/topical-reports/tr-th-legal">
              Town Hall Meeting on Legal Issues
            </NavLink>
          </li>

          <li className="topics-item">
            <span className="topics-h">Topic 9 &nbsp; -&nbsp;</span>
            <NavLink to="/topical-reports/tr-fs-history">
              History of AT Outcomes
            </NavLink>
          </li>

          <li className="topics-item">
            <span className="topics-h">Topic 10 -&nbsp;</span>
            <NavLink to="/topical-reports/tr-fs-methodsidentifyuse">
              Methods to Identify Assistive Technology Device Use
            </NavLink>
          </li>

          <li className="topics-item">
            <span className="topics-h">Topic 11 -&nbsp;</span>
            <NavLink to="/topical-reports/icf">
              The ICF in the Context of Assistive Technology (AT) Interventions
              and Outcomes
            </NavLink>
          </li>

          <li className="topics-item">
            <span className="topics-h">Topic 12 -&nbsp;</span>
            <NavLink to="/topical-reports/tr-discontinuance">
              Factors in Assistive Technology Device Abandonment: Replacing
              “Abandonment” with “Discontinuance”
            </NavLink>
          </li>

          <li className="topics-item">
            <span className="topics-h">Topic 13 -&nbsp;</span>
            <NavLink to="/topical-reports/tr-consumerfg">
              Focus Groups on Assistive Technology Use and Outcomes: A Consumer
              Perspective
            </NavLink>
          </li>
        </ul>
      </div>

      {/* <h1>Other Technical Reports</h1>
      <h2>General</h2>
      <ul>
        <li>
          <NavLink to="/topical-reports/icf">
            The ICF in the Context of Assistive Technology (AT) Interventions
            and Outcomes
          </NavLink>
        </li>
        <li>
          <NavLink to="/topical-reports/tr-discontinuance">
            Factors in Assistive Technology Device Abandonment: Replacing
            “Abandonment” with “Discontinuance”
          </NavLink>
        </li>
        <li>
          <NavLink to="/topical-reports/tr-consumerfg">
            Focus Groups on Assistive Technology Use and Outcomes: A Consumer
            Perspective
          </NavLink>
        </li>
      </ul> */}
      <h2>Database Related Reports</h2>
      <div className="list">
        <ol>
          <li>
            Clinical (public schools, rehabilitation programs, vocational
            services){" "}
          </li>
          <ul className="a left30">
            <li>
              Ohio's Assistive Technology Infusion Project (ATIP) -
              <ul className="left30">
                <li>
                  <NavLink to="/topical-reports/tr-atip">
                    {" "}
                    The Assistive Technology Infusion Project (ATIP) Database
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/topical-reports/ohioato">
                    Design of the Data Collection System for the Ohio Assistive
                    Technology Outcomes (OhioATO) Project (multi-page document
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="a left30">
              Analysis of existing clinical assistive technology service program
              databases
            </li>
            <ul className="left30">
              <li>
                <NavLink to="/topical-reports/tr-clinicaldb">
                  Clinical Service Program Database
                </NavLink>
              </li>
            </ul>
          </ul>
          <li>National population databases </li>
          <ul className="a left30">
            <li>
              National Health Information Survey-Disability (NHIS-D)
              <ul className="left30">
                <li>
                  <NavLink to="/topical-reports/tr-nhisd">
                    Data Base Analysis: 1994 and 1995 NHIS Phase II Disability
                    Followback Survey, Child Questionnaire
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="a left30">
              Assistive and information technology survey
              (NIDRR/RESNA/University of Michigan)
            </li>
          </ul>
          <li>
            Vocational rehabilitation
            <ul className="a left30">
              <li>
                RSA-911
                <ul className="left30">
                  <li>
                    <NavLink to="/topical-reports/tr-rsa911">
                      Topical Report - Vocational Rehabilitation Database
                      Analysis: RSA-911 Case Service Report and Database Linking
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/rsa911/rsa911">
                      Graphing of Rehabilitation Technology Data from the
                      RSA-911
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>Wisconsin DVR </li>
            </ul>
          </li>
        </ol>
      </div>

      <h2>Research Method Reports</h2>
      <div className="list">
        <ul>
          <li>
            <NavLink to="/topical-reports/tr-qualitative">
              Qualitative Research Discussions
            </NavLink>
          </li>
          <li>
            <NavLink to="/topical-reports/neq1summitreport">
              N=1 Assistive Technology (AT) Outcomes Summit
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TechnicalReports;
