import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom'
import './App.css';

//Pages
import HomePage from './pages/homepage/homepage.component'
import IDataDetails from './pages/idata/instrument-details'
import AllData from './pages/idata/all-idata'
import Contact from './pages/contact/contact.component'
import Footer from './components/footer/footer.component'
import PageNotFound from './components/page-not-found'
import Navbar from './components/navbar/navbar.component'
// import HomeNavbar from './components/navbar/navbar.homepage'
import TechnicalReports from './pages/technical-reports/technical-reports'
//Router Pages
import { PublicationsRouter } from './pages/publications/publications-router'
import { AtOutcomesRouter } from './pages/at-outcomes/at-outcomes-router'
import { TechnicalReportsRouter } from './pages/technical-reports/technical-reports-router'
import { IDataRouter } from './pages/idata/idata-router'
import { EducationalRouter } from './pages/educational/educational-router'
import { RelatedProjectsRouter } from './pages/related-projects/related-projects-router'
import { Rsa911Router } from './pages/rsa911/rsa911-router'

import CssBaseline from '@material-ui/core/CssBaseline';
import PublicationsNavbar from "./components/navbar/publications-navbar";
import AppBar from '@material-ui/core/AppBar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function App(props) {
  const location = useLocation()

  return (
    <div className="container">
      <CssBaseline />
      {location.pathname === '/' ? null : <Navbar />}
      {location.pathname.includes('/publications') ?
        <HideOnScroll {...props} >
          <AppBar  >
            <PublicationsNavbar />
          </AppBar>
        </HideOnScroll> : null}
      <Switch>
        <Route exact path='/' component={HomePage} />
        <Route path='/publications' component={PublicationsRouter} />
        <Route path='/at-outcomes' component={AtOutcomesRouter} />
        <Route path='/topics' component={TechnicalReports} />
        <Route path='/topical-reports' component={TechnicalReportsRouter} />
        <Route exact path='/idata/detail-idata/:id' component={IDataDetails} />
        <Route exact path='/idata/all-idata/:id' component={AllData} />
        <Route path='/idata' component={IDataRouter} />
        <Route path='/educational' component={EducationalRouter} />
        <Route path='/related-projects' component={RelatedProjectsRouter} />
        <Route path='/rsa911/' component={Rsa911Router} />
        <Route exact path='/contact' component={Contact} />
        <Route component={PageNotFound} />
      </Switch>
      <Footer />
    </div>
  );
}

export default App;