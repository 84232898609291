import React from 'react'
import './footer.styles.css'

function Footer() {
    return (
        <footer className='footer'>
            <p>Copyright © 2001-{(new Date().getFullYear())} ATOMS Project by the R2D2 Center at the University of Wisconsin-Milwaukee</p>
        </footer>
    )
}

export default Footer
