import React from 'react'
import { NavLink } from "react-router-dom";
import "./navbar.styles.css";
const Menu = ({ classes }) => {
    return (
        // className={` ${toggle ? "nav-open" : "nav-menu"}`}

        <ul id="navId" className={classes}>
            {/* onClick={() => setToggle(false)} */}
            <li className="nav-item">
                <NavLink
                    className="nav-link"
                    to="/publications"
                    activeClassName="active"
                >
                    Publications & Presentations
            </NavLink>
            </li>
            {/* <li className="nav-item">
          <NavLink
            className="nav-link"
            exact
            to="/at-outcomes/at-outcomes"
            activeClassName="active"
          >
            AT Outcomes
          </NavLink>
        </li> */}
            <li className="nav-item">
                <NavLink
                    className="nav-link"
                    exact
                    to="/topics"
                    activeClassName="active"
                >
                    Topics Of AT Outcomes
            </NavLink>
            </li>
            <li className="nav-item">
                <NavLink
                    className="nav-link"
                    exact
                    to="/idata"
                    activeClassName="active"
                >
                    Interactive Databases
            </NavLink>
            </li>
            {/* <li className='nav-item'><NavLink className='nav-link' exact to="/educational/educational" activeClassName='active'>Educational</NavLink></li>
                <li className='nav-item'><NavLink className='nav-link' exact to="/models/models" activeClassName='active'>Models</NavLink></li>
                <li className='nav-item'><NavLink className='nav-link' exact to="/annotated-websites/annotated-websites" activeClassName='active'>Sites</NavLink></li> */}
            <li className="nav-item">
                <NavLink
                    className="nav-link"
                    exact
                    to="/related-projects"
                    activeClassName="active"
                >
                    Other Resources
            </NavLink>
            </li>
        </ul>

    )
}

export default Menu
