import React from "react";
import { NavLink } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="content">
      <p align="center" style={{ fontSize: "95px", marginTop: "30px" }}>
        <strong>404</strong>
      </p>
      <p align="center" style={{ fontSize: "55px", marginBottom: "35px" }}>
        Page Not Found!
      </p>
      <p align="center" style={{ fontSize: "18px", marginBottom: "25px" }}>
        We're sorry, but we can't find the page you were looking for. It's
        probably something we've done wrong but now we know about it and we'll
        try to fix it.
      </p>
      <p style={{ textAlign: "center" }}>
        <NavLink
          exact
          to="/"
          style={{ textDecoration: "none", color: "black" }}
        >
          {">> "}Go to Home{" "}
        </NavLink>
      </p>
    </div>
  );
};

export default PageNotFound;
