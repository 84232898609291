import React from "react";
import { NavLink } from "react-router-dom";

const ErrorPage = ({ error, errorInfo }) => {
  return (
    <div className="content">
      <p
        align="center"
        style={{ fontSize: "55px", marginTop: "30px", color: "darkgray" }}
      >
        <strong>{error.toString()}</strong>
      </p>
      <p
        align="center"
        style={{ fontSize: "35px", marginBottom: "35px", color: "darkgray" }}
      >
        {errorInfo.componentStack}
      </p>

      <p style={{ textAlign: "center" }}>
        <NavLink
          exact
          to="/"
          style={{ textDecoration: "none", color: "black" }}
        >
          {">> "}Go to Home{" "}
        </NavLink>
      </p>
    </div>
  );
};

export default ErrorPage;
