import React, { Component } from "react";
import IDATA from "./IDATA_DATA_TABLE.json";
import "./idata.styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
export default class InstrumentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iData: IDATA.results[0].items,
    };
  }

  render() {
    const id = this.props.match.params.id;
    const instrumentDetails = this.state.iData.find(
      ({ idata_id }) => idata_id.toString() === id
    );

    return (
      <div className="content details-table">
        <h1 style={{ padding: "30px 0" }}>Instrument Details</h1>{" "}
        <div className="table-scroll">
          <table className="details-table">
            <tbody>
              <tr>
                <th>Instrument Title</th>
                <td>{instrumentDetails.title}&nbsp;</td>
              </tr>
              <tr>
                <th>Author(s)</th>
                <td>{instrumentDetails.authors}&nbsp;</td>
              </tr>
              <tr>
                <th>Year</th>
                <td>{instrumentDetails.year_version}&nbsp;</td>
              </tr>
              <tr>
                <th>Version</th>
                <td>{instrumentDetails.version}&nbsp;</td>
              </tr>
              <tr>
                <th>Current version?</th>
                <td>
                  {instrumentDetails.current_version === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Main category</th>
                <td>{instrumentDetails.category}&nbsp;</td>
              </tr>
              <tr>
                <th>Publisher</th>

                <td>{instrumentDetails.publisher}&nbsp;</td>
              </tr>
              <tr>
                <th>Price</th>
                <td>{instrumentDetails.price}&nbsp;</td>
              </tr>
              <tr>
                <th>Website (URL)</th>
                <td>
                  <a
                    href={`http://${instrumentDetails.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {instrumentDetails.url}&nbsp;
                  </a>
                </td>
              </tr>
              <tr>
                <th>Email address </th>
                <td>{instrumentDetails.email}&nbsp;</td>
              </tr>
              <tr>
                <th>Mailing address</th>
                <td>{instrumentDetails.mailing}&nbsp;</td>
              </tr>
              <tr>
                <th>Author's, vendor's, or publisher's description</th>
                <td>{instrumentDetails.their_description}&nbsp;</td>
              </tr>
              <tr>
                <th> R2D2 Center library reference number</th>
                <td>{instrumentDetails.library_number}&nbsp;</td>
              </tr>
              <tr className="row-details">
                <th colspan="2">
                  <h3>AT Service Delivery Area</h3>
                </th>
              </tr>
              <tr>
                <th>AAC</th>
                <td>
                  {instrumentDetails.aac_area === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Assistive listening</th>
                <td>
                  {instrumentDetails.ald_area === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Computer access</th>
                <td>
                  {instrumentDetails.ca_area === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Electronic aids to daily living (EADL) </th>
                <td>
                  {instrumentDetails.eadl_area === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Organizers</th>
                <td>
                  {instrumentDetails.organizers_area === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Mobility</th>
                <td>
                  {instrumentDetails.mobility_area === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Reading supports</th>
                <td>
                  {instrumentDetails.reading_area === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Seating &amp; positioning</th>
                <td>
                  {instrumentDetails.seatingpositioning_area === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Vision access</th>
                <td>
                  {instrumentDetails.vision_area === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Writing aupports</th>
                <td>
                  {instrumentDetails.writing_area === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Activities of daily living (ADL)</th>
                <td>
                  {instrumentDetails.adl_area === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Other</th>
                <td>
                  {instrumentDetails.other_area === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr className="row-details">
                <th colspan="2">
                  <h3>Disability Population </h3>
                </th>
              </tr>
              <tr>
                <th>Behavior</th>
                <td>
                  {instrumentDetails.behavior_disability === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Cognitive</th>
                <td>
                  {instrumentDetails.cognitive_disability === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Hearing</th>
                <td>
                  {instrumentDetails.hearing_disability === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Physical</th>
                <td>
                  {instrumentDetails.physical_disability === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Speech</th>
                <td>
                  {instrumentDetails.speech_disability === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Vision</th>
                <td>
                  {instrumentDetails.vision_disability === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Other</th>
                <td>
                  {instrumentDetails.other_disability === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr className="row-details">
                <th colspan="2">
                  <h3>Age/Program Population</h3>
                </th>
              </tr>

              <tr>
                <th>Birth - 5</th>
                <td>
                  {instrumentDetails.birth5_program === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Elementary</th>
                <td>
                  {instrumentDetails.elementary_program === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Middle &amp; high School</th>
                <td>
                  {instrumentDetails.mhs_program === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Vocational</th>
                <td>
                  {instrumentDetails.vocational_program === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>College</th>
                <td>
                  {instrumentDetails.college_program === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Business</th>
                <td>
                  {instrumentDetails.business_program === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Adult home</th>
                <td>
                  {instrumentDetails.adulthome_program === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr className="row-details">
                <th colspan="2">
                  <h3>Tool Format</h3>
                </th>
              </tr>

              <tr>
                <th>Checklist</th>
                <td>
                  {instrumentDetails.checklist_format === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Flowchart</th>
                <td>
                  {instrumentDetails.flowchart_format === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Online</th>
                <td>
                  {instrumentDetails.online_format === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Questionnaire</th>
                <td>
                  {instrumentDetails.questionnaire_format === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Rating Scale</th>
                <td>
                  {instrumentDetails.rating_format === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Sample based activities</th>
                <td>
                  {instrumentDetails.sample_format === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Software based activities</th>
                <td>
                  {instrumentDetails.swactivities_format === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Software based assessment</th>
                <td>
                  {instrumentDetails.swbased_format === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Survey</th>
                <td>
                  {instrumentDetails.survey_format === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr className="row-details">
                <th colspan="2">
                  <h3>Purpose of Assessment Measures</h3>
                </th>
              </tr>
              <tr>
                <th>Screening</th>
                <td>
                  {instrumentDetails.screening_purpose === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Referral</th>
                <td>
                  {instrumentDetails.referral_purpose === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Comprehensive assessment</th>
                <td>
                  {instrumentDetails.comprehensive_purpose === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Matching person &amp; technology</th>
                <td>
                  {instrumentDetails.matching_purpose === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Acquisition</th>
                <td>
                  {instrumentDetails.acquisition_purpose === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Implementation</th>
                <td>
                  {instrumentDetails.implementation_purpose === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Follow-up</th>
                <td>
                  {instrumentDetails.followup_purpose === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Impact</th>
                <td>
                  {instrumentDetails.impact_purpose === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
              <tr>
                <th>Outcome</th>
                <td>
                  {instrumentDetails.outcome_purpose === "yes" ? (
                    <FontAwesomeIcon icon={faCheck} size="s" color="green" />
                  ) : null}
                  &nbsp;
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
