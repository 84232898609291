import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import "./table.styles.css";
import TableRow from "@material-ui/core/TableRow";
import { NavLink } from "react-router-dom";

import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {},
  table: {},
});

export default function DataTable({ columns, rows, sortData }) {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="sticky table"
            // size="xxs"
            // style={{ width: "1px" }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    className="table-cell"
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      fontWeight: 400,
                      backgroundColor: "#dcdfe0",
                    }}
                  >
                    {column.label === "Version" ? (
                      column.label
                    ) : (
                      <button
                        className="table-cell button"
                        onClick={() => {
                          sortData(column.id);
                        }}
                      >
                        {column.label}{" "}
                        <FontAwesomeIcon
                          icon={column.toggle ? faAngleUp : faAngleDown}
                          size="xs"
                        />
                      </button>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.idata_id}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className="table-cell"
                        >
                          {column.id === "title" ? (
                            <NavLink
                              key={row.idata_id}
                              to={{
                                pathname: `/idata/detail-idata/${row.idata_id}`,
                                detailprops: {
                                  id: row.idata_id,
                                },
                              }}
                            >
                              {value}
                            </NavLink>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
