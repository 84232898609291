import React, { Suspense } from "react";
import ErrorBoundary from "../../components/ErrorBoundary";

export const TechnicalReportsRouter = (props) => {
  const pathname = props.location.pathname;
  const newPath = `${pathname}`.replace("/topical-reports/", "./");

  const Page = React.lazy(() => import(`${newPath}`));

  return (
    <React.Fragment>
      <ErrorBoundary>
        <Suspense fallback={<div style={{ height: "100vh" }}></div>}>
          <Page />
        </Suspense>
      </ErrorBoundary>
    </React.Fragment>
  );
};
