import React from "react";
import "./header.styles.css";
import Logo from "./Atomslogo-rotated2-lightgreen.png";
import { NavLink } from "react-router-dom";
import HomeNavbar from "../navbar/navbar.homepage";

const Header = () => {
  return (
    <header className="header">
      <div className="banner">
        <div className="logo-box">
          <NavLink className="logo-icon" exact to="/">
            <img className="logo-image" src={Logo} alt="logo" />
          </NavLink>

          <span className="logo-main">ATOMS</span>
          <span className="logo-sub">
            Assistive Technology Outcomes Measurement System
          </span>

          {/* <NavLink className='logo-main' exact to='/'><img className='logo-image' src={Logo} alt='logo' /></NavLink> 
                    <h1><span className="logo-sub">Assistive Technology Outcomes Measurement System</span></h1>*/}
        </div>
      </div>
      <HomeNavbar className="nav" />
    </header>
  );
};

export default Header;
