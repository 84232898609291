import React, { useState, useEffect } from "react";
import "./navbar.styles.css";
import { NavLink } from "react-router-dom";
import Menu from "./menu";
// import Logo from "./Atomslogo-rotated2-lightgreen.png";

const HomeNavbar = () => {
  // const [top, setTop] = useState(true);

  // useEffect(() => {
  //   document.addEventListener("scroll", trackScrolling);

  //   return function cleanup() {
  //     document.removeEventListener("scroll", trackScrolling);
  //   };
  // }, []);

  // const trackScrolling = () => {
  //   if (window.pageYOffset > 250) {
  //     setTop(false);
  //   } else setTop(true);
  // };
  const [toggle, setToggle] = useState(false);
  // useEffect(() => {
  //   if (window.innerWidth > 949) {
  //     setToggle(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleToggle = () => {
    toggle ? setToggle(false) : setToggle(true);
    console.log("toggled");
  };

  return (
    <nav className="navbar-clear">
      {/* // <nav className={top ? "navbar-clear" : "navbar-black"}> */}
      {/* <div className="branding" > */}
      {/* <div className="no-branding">
        <NavLink exact to="/" className="brand-link">
          <h2>
            <img src={Logo} alt="ATOMS" />
            <span className="branding-name">Atoms</span>
          </h2>
        </NavLink>
      </div> */}
      <div
        className={"burger"}
        onClick={handleToggle}
        // style={{ backgroundColor: "yellow" }}
      >
        <div className={!toggle ? "line1" : "line1-active"}></div>
        <div className={!toggle ? "line2" : "line2-active"}></div>
        <div className={!toggle ? "line3" : "line3-active"}></div>
      </div>
      {/* <div className="spacer"></div> */}
      <Menu classes="nav-menu space-between" />

      <div
        className={toggle ? "open side-menu" : "side-menu"}
        onClick={() => {
          setToggle(false);
        }}
      >
        <Menu />
      </div>
      {/* <ul
        className={toggle ? "nav-open fadeout " : "nav-menu fadeout"}
        id="navId"
        onClick={() => setToggle(false)}
      >
        <li className="nav-item">
          <NavLink
            className="nav-link"
            exact
            to="/publications"
            activeClassName="active"
          >
            Publications & Presentations
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            exact
            to="/topics"
            activeClassName="active"
          >
            Topics Of AT Outcomes
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            exact
            to="/idata"
            activeClassName="active"
          >
            Interactive Databases
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            className="nav-link"
            exact
            to="/related-projects"
            activeClassName="active"
          >
            Other Resources
          </NavLink>
        </li>
      </ul> */}
    </nav>
  );
};

export default HomeNavbar;
