import React, { useState, useEffect } from "react";
import { NavLink, useParams, useHistory } from "react-router-dom";
import iData from "./IDATA_DATA_TABLE.json";
import Table from "../../components/tables/idata-table";

import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import Checkbox from "@material-ui/core/Checkbox";

import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";

const myTheme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: { main: "#002984" },
  },
});

const useStyles = makeStyles(() => ({
  formControl: {
    paddingBottom: 30,
    minWidth: 200,
    maxWidth: 800,
  },
}));

const measures = [
  { name: "Screening", value: "screening_purpose" },
  { name: "Referral", value: "referral_purpose" },
  { name: "Comprehensive assessment", value: "comprehensive_purpose" },
  { name: "Matching person & technology", value: "matching_purpose" },
  { name: "Acquisition", value: "acquisition_purpose" },
  { name: "Implementation", value: "implementation_purpose" },
  { name: "Follow-up", value: "followup_purpose" },
  { name: "Impact", value: "impact_purpose" },
  { name: "Outcome", value: "outcome_purpose" },
];

const categoryArrayObjs = [
  { name: "All Categories", value: "ALL" },
  { name: "Augmentative and Alternative Communication (AAC)", value: "AAC" },
  { name: "Computer access (CA)", value: "CA" },
  { name: "School & education (SCH)", value: "SCH" },
  { name: "Seating & positioning (SP)", value: "SP" },
  { name: "Other (OTH)", value: "OTH" },
];

const AllIData = () => {
  const [state, setState] = useState({
    data: iData.results[0].items.sort((a, b) => a.title.localeCompare(b.title)),
    filteredData: [],
    filterByCategory: [],
    filterByMeasures: [],
    title: true,
    authors: false,
    year_version: false,
    category: false,
    publisher: false,
  });
  const params = useParams();
  // const [category, setCategory] = React.useState({});

  useEffect(() => {
    const filteredData =
      params.id === "all"
        ? [...state.data]
        : state.data.filter(
            (record) => record.category === params.id.toUpperCase()
          );
    setState({
      ...state,
      filteredData: [...filteredData],
      filterByCategory: [...filteredData],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortData = (sortBy) => {
    const data = [...state.filteredData].sort((a, b) => {
      if (a[sortBy] === "") {
        return 1;
      }
      if (b[sortBy] === "") {
        return -1;
      }
      if (a[sortBy] > b[sortBy]) {
        return 1;
      }
      return -1;
    });

    setState({
      ...state,
      filteredData: state[sortBy] ? data.reverse() : data,
      [sortBy]: !state[sortBy],
    });
  };

  const columns = [
    {
      id: "title",
      label: "Instrument Title",
      minWidth: 150,
      toggle: state.title,
    },
    {
      id: "authors",
      label: "Authors",
      minWidth: 50,
      toggle: state.authors,
    },
    {
      id: "year_version",
      label: "Year",
      minWidth: 50,
      toggle: state.year_version,
    },
    { id: "version", label: "Version", minWidth: 20 },
    {
      id: "category",
      label: "Category",
      minWidth: 80,
      toggle: state.category,
    },
    {
      id: "publisher",
      label: "Publisher",
      minWidth: 50,
      toggle: state.publisher,
    },
  ];

  const classes = useStyles();
  const [names, setNames] = React.useState([]);
  const [values, setValues] = React.useState([]);

  const history = useHistory();

  const handleChangeCategory = (e) => {
    history.push(`/idata/all-idata/${e.target.value.toLowerCase()}`);
    const key = e.target.value;
    const data = [...state.data];
    const filteredData =
      key === "ALL"
        ? [...data]
        : data.filter((record) => record.category === key);

    const newData = filteredData.filter((record) =>
      values.every((x) => record[x] === "yes")
    );

    setState({
      ...state,
      filteredData: [...newData],
      filterByCategory: [...newData],
    });
  };

  const handleChangePurpose = (e) => {
    const purpose = e.target.value[names.length].name;
    const key = e.target.value[names.length].value;
    const data = [...state.filterByCategory];
    let tempVal = [];

    names.includes(purpose)
      ? setNames(names.filter((x) => x !== purpose))
      : setNames([...names, purpose]);
    values.includes(key)
      ? setValues(values.filter((x) => x !== key))
      : setValues([...values, key]);
    values.includes(key)
      ? (tempVal = values.filter((x) => x !== key))
      : (tempVal = [...values, key]);

    const newData = data.filter((record) =>
      tempVal.every((x) => record[x] === "yes")
    );

    setState({ ...state, filteredData: [...newData] });
  };

  const rows = state.filteredData;

  return (
    <div className="content">
      <h1>Browse or Find Instruments through Sorting</h1>
      <h3 style={{ paddingBottom: "20px" }}>
        Select Category to Sort and Find Your Instrument and Select to Sort by
        Purpose
      </h3>

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="category">Select Category</InputLabel>
        <NativeSelect
          defaultValue={params.id.toUpperCase()}
          onChange={handleChangeCategory}
        >
          {categoryArrayObjs.map((x) => (
            <option value={x.value}>{x.name}</option>
          ))}
        </NativeSelect>
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Select Purpose of Assessment Measures</InputLabel>
        <Select
          multiple
          value={names}
          onChange={handleChangePurpose}
          input={<Input />}
          renderValue={(selected) => selected.join(", ")}
        >
          {measures.map((measure) => (
            <MenuItem key={measure.name} value={measure}>
              <ThemeProvider theme={myTheme}>
                <Checkbox checked={names.indexOf(measure.name) > -1} />
              </ThemeProvider>

              <ListItemText primary={measure.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Table
        rows={rows}
        columns={columns}
        sortData={sortData}
        style={{ paddingTop: "20px" }}
      />
    </div>
  );
};

export default AllIData;
