import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../../components/header/header.component";

const HomePage = () => {
  return (
    <div>
      <Header />
      <div className="content">
        <div style={{ marginTop: "-100px" }}>
          <p>
            The ATOMS Project was launched by a five year assistive technology
            (AT) outcomes and impacts project funded in part by the National
            Institute on Disability and Rehabilitation Research (NIDRR) under
            the Disability and Rehabilitation Research Projects (DRRP) program.
          </p>
          <p>
            This website hosts one of the largest resources specifically related
            to assistive technology outcomes. Documents include FieldScans,
            Technical Reports, Bibliographies, and informational databases.
            These are public resources that you can freely use. We simply
            request that you fully reference and cite our work. Plus, keep us
            informed. If the resources are helpful,
            <NavLink to="/contact" activeClassName="active">
              {" "}
              please let us know.
            </NavLink>
          </p>
          <p>
            The project is led by Dr. Roger O. Smith, Professor of Occupational
            Therapy, College of Health Sciences (CHS) and Dr. Dave L. Edyburn,
            Professor of Exceptional Education, School of Education. The ATOMS
            Project is a highly collaborative and interdisciplinary effort. We
            describe our complete team in more detail in the summary documents.
          </p>
        </div>
        <div align="center">
          <NavLink to="/topical-reports/tr-fs-taxonomiesmodels-fig1">
            <div className="figure-container">
              <div>
                <strong>Click to read description</strong>
              </div>
              <div className="figure-body">
                <img
                  src="/atomslogo.gif"
                  alt="A figure conveying the significance of the proposed ATOMS logo"
                  className="responsive"
                  style={{ maxWidth: "276px" }}
                  // width={276}
                  // height={101}
                />
              </div>
            </div>{" "}
          </NavLink>
        </div>{" "}
        <p>
          <a
            style={{ fontSize: "20px" }}
            href="https://uwm.edu/r2d2/methods/impact2-model/"
            rel="noopener noreferrer"
            target="_blank"
          >
            IMPACT2 Model
          </a>{" "}
          - Integrated Multi-Intervention Paradigm for Assessment and
          Application of Concurrent Treatments
        </p>
      </div>
    </div>
  );
};

export default HomePage;
