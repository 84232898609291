import React, { Suspense } from "react";
import ErrorBoundary from "../../components/ErrorBoundary";

export const PublicationsRouter = (props) => {
  const pathname = props.location.pathname;
  let newPath = `${pathname}`.replace("/publications/", "./");
  if (newPath === "/publications") {
    newPath = "./publications";
  }
  const Page = React.lazy(() => import(`${newPath}`));

  return (
    <React.Fragment>
      <ErrorBoundary>
        <Suspense fallback={<div style={{ height: "100vh" }}></div>}>
          <Page />
        </Suspense>
      </ErrorBoundary>
    </React.Fragment>
  );
};
