import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./navbar.styles.css";
import Menu from "./menu";
import Logo from "../header/Atomslogo-rotated2-lightgreen.png";
// import Logo from "./atomslogo.gif";
const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    toggle ? setToggle(false) : setToggle(true);
    // console.log("toggled");
    // navSlide()
    // const nav = document.querySelector('.nav-menu')
    // const burger1 = document.querySelector('.line1')
    // nav.classList.toggle('nav-active')
    // burger1.classList.toggle('.burger-actice-line1')
  };

  return (
    <nav className={"navbar-black"}>
      <div className="burger" onClick={handleToggle}>
        <div className={!toggle ? "line1" : "line1-active"}></div>
        <div className={!toggle ? "line2" : "line2-active"}></div>
        <div className={!toggle ? "line3" : "line3-active"}></div>
      </div>
      <div className="spacer"></div>
      <NavLink exact to="/" className="brand-link">
        <div className="branding">
          {/* <img src={Logo} alt="ATOMS" height="85px" /> */}
          <img src={Logo} alt="ATOMS" height="29px" />

          <span className="branding-name">Atoms</span>
        </div>
      </NavLink>
      {/* <div className="branding">
        <NavLink exact to="/" className="brand-link">
          <h2>
            <img src={Logo} alt="ATOMS" />
            <span className="branding-name">Atoms</span>
          </h2>
        </NavLink>
      </div> */}
      <div className="spacer"></div>
      <Menu classes="nav-menu space-between" />

      <div
        className={toggle ? "open side-menu" : "side-menu"}
        onClick={() => {
          setToggle(false);
        }}
      >
        <Menu />
      </div>
    </nav>
  );
};

export default Navbar;
