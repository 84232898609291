import React from "react";
import "./navbar.styles.css";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

const PublicationsNavbar = () => {
  const menuItems = [
    { id: 1, name: "2001", to: "/publications/atomsbiblio2001" },
    { id: 2, name: "2002", to: "/publications/atomsbiblio2002" },
    { id: 3, name: "2003", to: "/publications/atomsbiblio2003" },
    { id: 4, name: "2004", to: "/publications/atomsbiblio2004" },
    { id: 5, name: "2005", to: "/publications/atomsbiblio2005" },
    { id: 6, name: "2006", to: "/publications/atomsbiblio2006" },
    { id: 7, name: "2007", to: "/publications/atomsbiblio2007" },
    { id: 8, name: "Handouts", to: "/publications/conf2002" },
  ];
  return (
    <div className="scrollmenu">
      <ul className="nav-menu-p">
        {menuItems.map((item) => (
          <li key={item.id} className="nav-item">
            <NavLink to={item.to} style={{ color: "black" }}>
              {item.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PublicationsNavbar;
