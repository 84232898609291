import React from "react";

const Contact = () => {
  return (
    <div className="content">
      <br />
      <h3>EMAIL</h3>

      <ul>
        <li>
          Project questions, comments and suggestions:{" "}
          <a href="mailto: atoms@uwm.edu">ATOMS Coordinator</a> (atoms@uwm.edu)
        </li>
        <li>
          Website questions, comments, and suggestions:{" "}
          <a href="mailto: atomsweb@uwm.edu">ATOMS Webmaster</a>{" "}
          (atomsweb@uwm.edu)
        </li>
      </ul>

      <h3>MAILING ADDRESS</h3>

      <p>
        ATOMS Project <br />
        Rehabilitation Research Design & Disability (R<sub>2</sub>D<sub>2</sub>)
        Center <br />
        PO Box 413 <br />
        Milwaukee, WI 53201 <br />
        USA
      </p>

      <h3>PHYSICAL ADDRESS</h3>
      <p>
        Enderis Hall 135 <br />
        2400 E. Hartford Ave <br />
        Milwaukee, WI 53211 <br />
        USA
      </p>

      <h3>TELEPHONE</h3>
      <p>
        voice (414) 229-6803 <br />
        TTY (414) 229-5628 <br />
        fax (provided upon request)
      </p>
    </div>
  );
};

export default Contact;
